import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box, Heading } from "grommet"
import { FormClose } from "grommet-icons"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Box align="center" pad="large" justify="center">
      <Box direction="row" gap="small" align="center">
        <FormClose size="xlarge" color="status-error" />
        <Heading>NOT FOUND</Heading>
      </Box>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Box>
  </Layout>
)

export default NotFoundPage
